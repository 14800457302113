import * as React from "react"

export function Checkbox() {
    const [checked, setChecked] = React.useState(false)

    const toggleCheckbox = () => setChecked(!checked)

    return (
        <div
            onClick={toggleCheckbox}
            style={{
                width: "20px",
                height: "20px",
                border: checked ? "none" : "2px solid #EFEFEF",
                backgroundColor: checked ? "#0786FD" : "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "4px",
            }}
        >
            {checked && <span style={{ color: "white" }}>✓</span>}
        </div>
    )
}
